const state = {
    date_form: {
        // 媒体类别id
        category_id: '',
        // 搜索标题
        title: '',
        //排序-方式
        order: '',
        //排序-类别
        sort: '',
        //是否收藏
        collect: 0,
        // 平台--门户id
        portal_id: -1,
        // 行业id
        industry_id: -1,
        // 地区id
        area_id: -1,
        //收录类型id
        inlet_category: -1,
        // 头条价格
        headline_price_id: -1,
        // 价格id || 普条价格 -- 小红书图文价格
        price_id: -1,
        // 参考粉丝id
        fans_num: -1,
        // 平均阅读量
        views: -1,
        //小红书图文点击量
        image_text_views: -1,
        //小红书视频点击量
        video_price_views: -1,
        // 头条价格
        member_headline_price: -1,
        // 转发价格
        forward_price_id: -1,
        // 图文价格
        image_text_price: -1,
        // 视频价格
        video_price: '',
        // 是否认证
        is_v_auth: '',
        // 是否官方
        is_official: '',
        // 焦点图
        is_pub_image: '',
        // 可发链接
        is_pub_url: '',
        // 可带链接
        is_pub_link: '',
        // 可发视频
        is_pub_video: '',
        // 可发联系方式
        is_pub_contact: '',
        // 周末可发
        is_pub_weekend: '',
        // 受众性别
        audience_sex: -1,
        // 受众年龄
        audience_age: -1,
    },
    spce_title: '',
}
const mutations = {
    SET_DATE(state, data) {
        state.date_form = data
        state.date_form.spce_title = ''
    }
}
export default {
    state,
    mutations
}