<template>
    <div>
        <el-tooltip popper-class="my_popper" enterable v-if="text != null && text != '' && text.length > 14"
            class="item" effect="dark" :content="text" placement="top">
            <el-button>{{ text.slice(0, 14) + '...' }}</el-button>
        </el-tooltip>
        <p v-else>{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        text: {
            type: [String],
            default: '',
        }
    },
}
</script>

<style lang=scss scoped>
p {
    font-size: 14px;
    color: #000;
    text-align: center;
}

.orver_h3 {
    font-weight: bold;
}

.my_popper {
    width: 200px !important;
}

::v-deep .el-button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    // width: 12rem;
    height: 14px;
    white-space: normal;
    overflow: hidden; //超出隐藏，其余内容不可见
    text-overflow: ellipsis; //文本溢出后用省略号显示
    color: #000;
    border: 0 !important;
    text-align: center;

    span {
        //display: block;
        font-size: 14px;
        color: #000;
        text-align: center;
    }
}
</style>