<template>
  <div>
    <el-divider content-position="left">{{ text }}</el-divider>
  </div>
</template>

<script>
  export default {
    props:{
      text:{
        type:String,
        default:'特殊类目'
      }
    },
    data(){
      return{}
    },
    methods:{},
 }
</script>

<style lang=scss scoped>
::v-deep .el-divider__text{
    font-size: 14px;
}
</style>