<template>
  <div>
    <a href="http://www.cac.gov.cn/2021-10/18/c_1636153133379560.htm" target="_blank" class="whiteList">稿源白名单</a>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang=scss scoped>
.whiteList {
  padding: 12px 20px;
  box-sizing: border-box;
  color: #fff;
  border-radius: 3px;
  background: #108CDD;
  text-decoration: none;
  margin-left: 15px;
}
</style>