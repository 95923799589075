let setLocalStorage = function (key, value) {
    // var curtime = new Date().getTime() // 获取当前时间 ，转换成JSON字符串序列
    var valueDate = JSON.stringify(value)
    try {

        localStorage.setItem(key, valueDate)
    } catch (e) {
        /* if(e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              console.log("Error: 本地存储超过限制");
              localStorage.clear();
          } else {
              console.log("Error: 保存到本地存储失败");
          } */
        // 兼容性写法
        if (isQuotaExceeded(e)) {
            localStorage.clear()
        } else {
        }
    }
}

let getLocalStorage = function (key) {
    // let signTime = localStorage.getItem('signTime') //登陆时间
    // var exp = 3600 * 24 * 1000 // 一天的毫秒数
    // var exp = 60 * 1000 // 一天的毫秒数
    if (localStorage.getItem(key)) {
        var vals = localStorage.getItem(key) // 获取本地存储的值
        var dataObj = JSON.parse(vals) // 将字符串转换成JSON对象
        // 如果(当前时间 - 存储的元素在创建时候设置的时间) > 过期时间
        // var isTimed = (new Date().getTime() - dataObj.timer) > exp
        // console.log(Date.now());
        // let signNum = 3600 * 24 * 7 * 1000
        // console.log('signTime:', Date.now());
        // if ((Date.now() - signTime) > exp == true) {

        //     // 设置1天过期时间
        //     localStorage.removeItem('user_info')
        //     // return this.$message({
        //     //     offset: 80,
        //     //     message: '身份已过期,请重新登录',
        //     //     type: 'warning',
        //     // })
        // } else {
            var newValue = dataObj
        // }
        return newValue

    } else {
        return null
    }
    // if (localStorage.getItem(key)) {
    //     var vals = localStorage.getItem(key) // 获取本地存储的值
    //     var dataObj = JSON.parse(vals) // 将字符串转换成JSON对象
    //     // 如果(当前时间 - 存储的元素在创建时候设置的时间) > 过期时间
    //     var isTimed = (new Date().getTime() - dataObj.timer) > exp
    //     if (isTimed) {
    //         console.log('存储已过期')
    //         localStorage.removeItem(key)
    //         return null
    //     } else {
    //         var newValue = dataObj
    //     }
    //     return newValue
    // } else {
    //     return null
    // }
}
let removeLocalStorage = function (key){
    if(localStorage.getItem(key)){
        localStorage.removeItem(key)
    }
}
let isQuotaExceeded = function (e) {
    var quotaExceeded = false
    if (e) {
        if (e.code) {
            switch (e.code) {
                case 22:
                    quotaExceeded = true
                    break
                case 1014: // Firefox
                    if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                        quotaExceeded = true
                    }
                    break
            }
        } else if (e.number === -2147024882) { // IE8
            quotaExceeded = true
        }
    }
    return quotaExceeded
}


export default {
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage
}