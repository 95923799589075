<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box" id="nav" :class="scrollTopNum == 0 ? 'box1' : 'box2'" @mouseleave="navTab(0)"
         @mouseenter="navTab(1)" :style="{ 'height': !nav_height ? '80px' : '150px' }">
      <!--  -->
      <div class="flex nav">
        <div class="flex" style="align-items: center">
          <img :src="imgSrc" alt="" style="width: 147px; margin-right: 50px; cursor: pointer" @click="pushHome"/>
          <ul class="flex nav_list">
            <li :class="$store.state.nav_show == index ? 'active' : ''" v-for="(item, index) in nav_list" :key="index"
                @mouseleave="childNone(item.id)" @mouseenter="childShow(item.id)" @click.prevent="router_fn(index)">
              {{ item.name }}
              <ul class="flex nav_child_list" v-show="item.children_show">
                <li v-for="(child_item, child_index) in item.children" :key="child_index" style="padding: 0 20px"
                    @click.stop="router_child_router(index, child_index)">{{ child_item.title }}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="login_but flex" v-if="!user_info">
          <button
              style="border:0;border-radius: 4px; color: #fff; background: #337ab7;box-sizing: border-box; width: 100px;height: 40px; margin-right: 20px;cursor: pointer;"
              @click="bezel(2)">注册
          </button>
          <button type="primary"
                  style="border: 0; border-radius: 4px; color: #fff; background: #FFA200;box-sizing: border-box; width: 100px;height: 40px;cursor: pointer;"
                  @click="bezel(1)">登录
          </button>
        </div>
        <div class="head_box flex" v-else-if="user_info">
          <div class="flex heade_name_box">
            <p class="userName" :style="{ color: nameColor ? nameColor : '#fff' }">您好，{{user_info.username }}</p>

            <div class="head_vip flex">
              <span v-show="user_info.group_id == 2"></span>
              <span v-show="user_info.group_id == 3"></span>
            </div>
          </div>
          <!-- <img :src="user_info.avatar" alt="" class="avatar" /> -->
          <el-button type="primary" @click="goUser('/user/index')">进入媒介中心</el-button>
<!--          <el-button type="primary" @click="goUser('/placard_user/placard_index')">进入广告中心</el-button>-->

        </div>
      </div>

    </div>

    <el-dialog :modal="false" lockScroll :visible.sync="$store.state.loginShow" :show-close="false"
               :destroy-on-close="true">
      <h3 class="login_title">欢迎进入<span>企宣通</span></h3>
      <div class="loginBox flex">
        <p :class="loginNum == 1 ? 'loginShow' : ''" @click="loginFn(1)">
          登录
        </p>
        <p :class="loginNum == 2 ? 'loginShow' : ''" @click="loginFn(2)">
          注册
        </p>
      </div>
      <!-- 提交表单 -->
      <div class="from_box">
        <div class="login_box flex">
          <p>手机号:</p>
          <el-input @keyup.enter.native="submitForm" v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </div>
        <div class="login_box flex">
          <p>密码:</p>
          <el-input @keyup.enter.native="submitForm" type="password" v-model="form.password"
                    placeholder="请输入密码"></el-input>
        </div>
        <div class="verificationCode_box flex">
          <div class="from">
            <p>验证码:</p>
            <el-input v-model="form.verificationCode" placeholder="请输入图片验证码"
                      @keyup.enter.native="submitForm"></el-input>
          </div>
          <img @click="codeFn" :src="code" alt="" style="width: 120px; cursor: pointer"/>
          <!-- <VueImage @getIdentifyCode="getIdentifyCodes" /> -->
        </div>
        <!-- <div class="flex">
          <el-checkbox v-model="form.clause"></el-checkbox>
          <p style="color: #999999; font-size: 1.2rem; margin-left: 0.8rem">
            我已阅读并同意<span style="font-size: 1.2rem; color: #168ad9">《企宣通服务条款》</span>
          </p>
        </div> -->
      </div>
      <button
          style="display: block; width:60%;height: 3rem;border-radius: 0.5rem; border: 0; background: #168ad9;margin: 0 auto; color: #fff;"
          @click="submitForm">登录
      </button>
      <div class="flex" style="justify-content: space-between;width: 60%;margin: 0 auto;">
        <p style="
					color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;
				" @click="$router.push('/Register')">
          还没有账号?立即注册
        </p>
        <p @click="goforgetPassword" style="color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;">忘记密码</p>
      </div>
    </el-dialog>
    <forgetPassword @getMobel="getMobel"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import {goAi} from '@/util/goAi'
import forgetPassword from '@/components/forgetPassword.vue'

export default {
  name: '',
  components: {
    forgetPassword
  },
  props: {
    nameColor: String,
  },
  created() {
    // console.log('nav created:', this.user_info);
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  mounted() {
    this.handleScroll()
    this.codeFn()
    this.getMediaNav()
    this.user_info = this.$util.getLocalStorage('user_info')
  },
  computed: {
    ...mapState(['menu_active', 'navShow']),
    ...mapMutations(['SETACTIVEMENU', 'login_tab', 'nav_tab']),

  },
  watch: {},
  data() {
    return {
      imgSrc: require('@/assets/nav/black.png'),
      scrollTopNum: 0, // 计算器
      nav_list: [
        {
          id: 0,
          name: '首页',
          path: '/'
        },
        {
          id: 1,
          name: '媒介投放',
          path: '/media/News',
          children_show: false,
          children: []
        },
        // {
        //   id: 2,
        //   name: '广告投放',
        //   path: '/placard_media_index/outdoors',
        //   children_show: false,
        //   children: []
        // },
        {
          id: 3,
          name: '一言一文',
          path: '/wordNav'
        },

        {
          id: 4,
          name: '享视界',
          path: '/aiNav'
        },

        {
          id: 5,
          name: '产品合作',
          path: '/product',
          children_show: false,
          children: [{
            id: 101,
            title: '媒体发布',
            path: '/media/News'
          }, {
            id: 102,
            title: '技术开发',
            path: '/technical'
          }, {
            id: 103,
            title: '视频制作',
            path: '/product'
          }, {
            id: 104,
            title: '可信网站',
            path: '/credible'
          }, {
            id: 105,
            title: '一言一文',
            path: '/word'
          }, {
            id: 106,
            title: '享视界',
            path: '/enjoy'
          },
            {
              id: 110,
              title: 'AI数字人',
              path: '/digitalHumans',
              show: 3,
            }],
        },
        {
          id: 6,
          name: '技巧指南',
          path: '/guide',
          children_show: false,
          children: [{
            id: 107,
            title: '营销技巧',
            path: '/guide'
          }, {
            id: 108,
            title: '拍摄技巧',
            path: '/guide',
            show: 1,
          }, {
            id: 109,
            title: '写稿技巧',
            path: '/guide',
            show: 2,
          }],
        },
        {
          id: 7,
          name: '关于我们',
          path: '/about',
        }
      ],

      childTitle: '',
      loginNum: 1,
      form: {
        mobile: '', //账号
        password: '', //密码
        clause: false, //服务条款
        verificationCode: '',
      },
      code: '', //生成的验证码
      fullscreenLoading: false,
      show: false,
      user_info: {},
      loginShow: this.$route.params.loginShow ? 1 : 0,
      nav_height: false,

    }
  },
  methods: {
    navTab(status) {
      if (status == 1) {
        this.imgSrc = require('@/assets/nav/white.png')
      } else {
        this.imgSrc = require('@/assets/nav/black.png')
      }
    },
    goforgetPassword() {
      this.$store.state.passwordShow = true
      this.$store.state.loginShow = false
      this.form = {
        mobile: '', //账号
        password: '', //密码
        clause: false, //服务条款
        verificationCode: '',
      }
    },
    /*修改密码过来后*/
    getMobel(mobile) {
      this.$store.state.passwordShow = false

      if (mobile) {
        this.form.mobile = mobile
      }
      this.bezel(1)
    },
    getMediaNav() {
      this.curlGet('/api/medium/init').then(res => {
        if (res.data.code) {
          this.nav_list.forEach((item, index) => {
            if (item.path == '/media/News') {
              item.children = res.data.data
            }
          })
          let path_array2 = ['/media/News', '/media/WeMedia', '/media/PaperMedia', '/media/OfficialAccounts', '/media/wb', '/media/RedBook', '/media/ShortVideo', '/media/SetMedia']
          for (const key in this.nav_list[1].children) {
            this.$set(this.nav_list[1].children[key], 'path', path_array2[key - 1])
            console.log(this.nav_list[1].children[key])
            if (this.nav_list[1].children[key].id == 99) {
              this.nav_list[1].children[key].path = '/media/SetMedia'
            }
            // console.log(this.nav_list[1].children[key].path)
          }
          console.log(this.nav_list[1].children);
        }
      })
      this.curlGet('/api/guanggao_info/init').then(res => {
        // this.nav_list[2].children = [res.data.data]
        let path_array2 = ['/placard_media_index/outdoors', '/placard_media_index/network', '/placard_media_index/journal', '/placard_media_index/tv']
        this.nav_list.forEach((item, index) => {
          if (item.path == '/placard_media_index/outdoors') {
            for (const key in res.data.data) {
              item.children.push({
                id: res.data.data[key].id,
                title: res.data.data[key].title,
                path: path_array2[key - 1]
              })
            }
          }
        })


      })
    },
    goUser(url) {
      this.$store.commit('nav_tab', 0)
      this.$store.commit('SETACTIVEMENU', url)
      this.$router.push(url)
    },
    // 登录-注册选项卡
    loginFn(num) {
      this.loginNum = num
      if (this.loginNum == 2) {
        console.log(num)
        this.$store.state.loginShow = false
        this.$router.push({
          path: '/register',
        })
      } else {
      }
    },
    bezel(id) {
      this.loginNum = id
      if (this.loginNum == 1) {
        this.$store.state.loginShow = true
        this.codeFn()
      } else {
        this.$router.push({
          path: '/register',
        })
      }
    },
    // 路由跳转
    router_fn(index) {
      this.$store.commit('nav_tab', index)
      this.$router.push(this.nav_list[index].path)
    },
    router_child_router(index, child_index) {
      if (this.nav_list[index].children[child_index].show) {
        this.$router.push({
          path: this.nav_list[index].children[child_index].path,
          query: {
            show: this.nav_list[index].children[child_index].show
          }
        })
      } else {
        this.$router.push(this.nav_list[index].children[child_index].path)
      }
    },
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, '滚动距离')
      this.scrollTopNum = scrollTop
      if (!this.scrollTopNum) {
        this.imgSrc = require('@/assets/nav/black.png')
      } else {
        this.imgSrc = require('@/assets/nav/white.png')
      }
    },
    submitForm() {
      if (this.form.mobile == '') {
        return this.$message({
          message: '请输入账号',
          type: 'warning',
        })
      } else if (this.form.password == '') {
        return this.$message({
          message: '请输入密码',
          type: 'warning',
        })
      } else if (this.verificationCode == '') {
        return this.$message({
          message: '验证码不能为空!',
          type: 'warning',
        })
      } // else if (!this.form.clause) {
      // 	console.log(this.form.clause)
      // 	return this.$message({
      // 		message: '请阅读并同意服务条款',
      // 		type: 'warning',
      // 	})
      // }
      this.curlPost('/api/user/login', {
        account: this.form.mobile,
        password: this.form.password,
        captcha: this.form.verificationCode,
      }).then((res) => {
        if (res.data.code) {
          this.$nextTick(() => {
            localStorage.setItem('signTime', Date.now())
            this.$util.setLocalStorage('user_info', res.data.data.userinfo, 200) // 写入local storage
            this.$message({
              offset: 80,
              message: res.data.msg,
              type: 'success',
            });
            this.form = {
              mobile: '', //账号
              password: '', //密码
              clause: false, //服务条款
              verificationCode: '',
            }
            this.code = '' //生成的验证码
            this.$store.commit('login_tab', false)

            // this.$user_info = res.data.data.userinfo
            // this.$router.push('/user/userIndex')
            location.reload()
          })
        } else {
          this.codeFn()
          return this.$message({
            offset: 80,
            message: res.data.msg,
            type: 'warning',
          })
        }
      })
    },
    codeFn() {
      this.curlGetCode('/api/common/captcha').then((res) => {
        if (res.status == 200) {
          // 以下为精髓
          let blob = new Blob([res.data]) // 返回的文件流数据
          let url = window.URL.createObjectURL(blob) // 将他转化为路径
          this.code = url // 将转换出来的路径赋值给变量，其实和上一步结合就可以
        }
      })
    },
    pushHome() {
      this.$store.commit('nav_tab')
      this.$router.push('/')
    },
    // 控制子菜单的显示隐藏
    childShow(id) {
      this.nav_list.forEach((item, index) => {
        if (item.id == id && item.hasOwnProperty('children_show')) {
          item.children_show = true
          this.nav_height = true
        }
      })
    },
    // 控制子菜单的显示隐藏
    childNone(id) {
      this.nav_list.forEach((item, index) => {
        if (item.hasOwnProperty('children_show')) {
          item.children_show = false
          this.nav_height = false
        }
      })
    },
    // 去功能选择页面
    async goAiNav() {
      const aiStatus = await goAi()
      if (aiStatus == 'ok') {
        this.$router.push('/aiNav')
      } else {
        return this.$store.state.loginShow = true
      }
    },
  },

  // 滚动重置
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/nav';
@import '@/scss/nav_h5';
</style>