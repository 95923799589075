import {Message} from "element-ui";
import {curlPost} from '@/api/request'

export function goAi() {
    return new Promise((resolve) => {
        let $user_info = JSON.parse(localStorage.getItem('user_info'))
        // console.log($user_info);
        if ($user_info) {
            // console.log('执行请求')
            curlPost('/file/adduser', {
                nickname: $user_info.username,
                mobile: $user_info.mobile,
                uid: $user_info.id,
            }).then(res => {
                console.log('生成结果', res)
                if (res.data.code) {
                    return resolve('ok')

                } else {
                    // Message.error(res.data.msg);
                    return resolve('err')
                }
            })
        } else {
            // Message.error(res.data.msg);
            return resolve('err')
        }
    })
}