const state = {
    car_list: [],
    fill_form: {
        title: '',
        file: '',
        fill_name: '',
        content: ''
    },
    date_form: {

        category_id: '',
        portal_id: -1,
        area_id: -1,
        price_id: -1,
        classify_id: -1,
        collect: 0,
        sort: '',
        order: '',
    },
    // 广告订单表单提交
    order_form: {
        guanggao_name: '',
        category_id: 1,
        order_status: '',
        time: [],
    },
    // 我的收单
    manuscript_form: {
        guanggao_name: '',
        category_id: 1,
        order_status: '',
        time: []
    },
    // 我的广告列表导航切换
    pub_index: 0,
    add_index: 0,
    // 申请发票
    apply_for_invoice_title: '1'
}
const mutations = {
    SET_DATE_FORM(state, data) {
        console.log(data);
        state.date_form[data.index] = data.data
        console.log(state.date_form);


    },
    SET_PLACARD_FILE(state, data) {
        console.log(state.fill_form);
        state.fill_form[data.index] = data.file
        console.log(state.fill_form);
    },
    SET_PLACARD_DATE(state, data) {
        state.date_form = data
    },
    SET_CAR_LIST(state, target_item) {
        if (!state.car_list.length) {
            // 如果车列表为空，直接添加目标项
            state.car_list.push(target_item);
        } else {
            // 查找是否已存在同ID的项
            let existingItem = state.car_list.find(item => item.id === target_item.id);
            if (existingItem) {
                // 如果存在，并且目标项符合条件，更新现有项
                if (target_item.type !== '' || target_item.check) {
                    Object.assign(existingItem, target_item); // 更新现有项
                }
            } else {
                // 如果不存在同ID的项，添加目标项
                state.car_list.push(target_item);
            }
        }
    },
    SET_DATA(state, data) {
        state[data.form] = data.value;
    },
    SET_ORDER_DATA(state, data) {
        state.order_form[data.index] = data.value;
        console.log(state.order_form);
    },
    DELETE_LIST(state, target_id) {
        state.car_list.forEach((item, index) => {
            if (item.id === target_id) {
                state.car_list.splice(index, 1);
            }
        })
    },
    RESET_FILL_FORM(state, date_form) {
        state.car_list = [];
        if (date_form) {
            state.date_form = date_form;
        } else {
            state.date_form = {
                category_id: state.date_form.category_id,
                portal_id: -1,
                classify_id: -1,
                area_id: -1,
                price_id: -1,
                collect: 0,
                sort: '',
                order: '',
            }
        }

        state.fill_form = {
            title: '',
            file: '',
            content: ''
        }
    },
    SET_PUB_INDEX(state, index) {
        state.pub_index = index;
    },
    SET_MANUSCRIPT_ORDER_DATA(state, data) {
        state.manuscript_form[data.index] = data.value;
    },
    CAR_LIST_RESET(state) {
        state.car_list = []
    },
}
export default {
    state,
    mutations
}