<template>
    <div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
            <p class="title_p">{{ title }}：</p>
            <div class="flex upload_img" v-for="(item, index) in imageUrl" :key="index">
                <img v-if="imageUrl.length > 0" :src="item" alt="" style="width: 94px" />
                <!-- 删除图标 -->
                <span v-if="imageUrl.length > 0" class="delete_icon el-icon-delete" @click="beforeMove(index)"></span>
            </div>
            <el-upload v-if="imageUrl.length != limit" class="avatar-uploader" @submit="uploadFn()"
                action="/api/common/upload" :headers="headers" accept=".jpg,.png" :show-file-list="false" :limit="limit"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="margin: 4rem 0 0 2rem" v-if="imageUrl.length != limit">jpg、png、gif</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        logo: String,
        limit: {
            type: Number,
            default: 1,
        },
        case_url: {
            type: String,
            default: '',
        },
    },
    mounted(){
        console.log(this.logo);
    },
    watch: {
        case_url(val) {
            if (val && this.url_push_num === 0 && this.$route.query.id) {
                this.url_push_num++;
                this.imageUrl = val.split(',')
                console.log(this.imageUrl);

            }
        }
    },
    data() {
        return {
            url_push_num: 0,
            imageUrl: [],
            headers: {
                token: this.$user_info.token,
            },
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl.push(res.data.fullurl)
            const arr_url = this.imageUrl.join(',')

            this.$emit('get_img', arr_url)
            // this.imageUrl = URL.createObjectURL(file.raw)
        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

            const extension =
                testmsg === 'jpg' || testmsg === 'png'
            const isLt2M = file.size / 1024 / 1024 < 5

            if (!extension) {
                this.$message.error('上传图片类型只能是 JPG,PNG格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!')
            }
            return extension && isLt2M
        },
        beforeMove(index) {
            this.$confirm('是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.imageUrl.splice(index, 1)
                    const arr_url = this.imageUrl.join(',')
                    this.$emit('get_img', arr_url)
                })
        },
    },
}
</script>

<style lang=scss scoped>
.title_p {
    text-align: right;
    padding-left: 13px;
    width: auto;
    white-space: nowrap;
    font-size: 14px;
}

.title_p2 {
    text-align: right;
    // width: 130px;

    font-size: 1.22rem;
}

::v-deep .el-upload--picture-card {
    width: 148px;
    height: 148px;
}

.upload_img {
    cursor: pointer;
    position: relative;
    margin-right: 10px;

    &:hover {
        .delete_icon {
            opacity: 1;
        }
    }

    .delete_icon {
        opacity: 0;
        transition: 0.2s;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 2rem;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 9.58rem;
    height: 9.58rem;
    display: block;
}

.avatar-uploader-icon {
    border: 0.17rem dashed #c5e6fc !important;
    width: 90px;
    height: 90px;
    line-height: 90px;
}
</style>