import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import md5 from 'md5'
import SlideVerify from 'vue-monoplasty-slide-verify';
import VueClipboard from 'vue-clipboard2'
import util from './util/util.js'
import navigation from '@/components/nav.vue'
import {curlGet, curlPost, curlGetCode} from './api/request'
import {pageChangeOne} from './util/pageChangeOne.js'
import {jumpLink} from './util/jumpLink.js'
import JSONExcel from 'vue-json-excel'
// let $user_info = 
import {getUserInfo} from './util/getUserInfo'
import {shopDate} from '@/util/shopDate.js'
import {goLink} from './util/goLink'
import {downloadByBlob} from './util/img_link.js'
import footerBot from './components/footerBot.vue'
import collect from './components/collect.vue'
import upLoadImg from './components/upLoadImg.vue'
import upLoadVideo from './components/upLoadVideo.vue'
import specialText from './components/specialText.vue'
import aiNum from './components/aiNum.vue'
import aiPoup from './components/aiPoup.vue'
import mediaWhiteList from './components/mediaWhiteList.vue'
import td_remark from './components/td_remark.vue'
import pagination from './components/pagination.vue'

Vue.component('pagination', pagination)
Vue.component('td_remark', td_remark)
Vue.component('mediaWhiteList', mediaWhiteList)
Vue.component('aiNum', aiNum)
Vue.component('aiPoup', aiPoup)
Vue.component('upLoadVideo', upLoadVideo)
Vue.component('upLoadImg', upLoadImg)
Vue.component('collect', collect)
Vue.component('specialText', specialText)
Vue.component('footerBot', footerBot)
Vue.use(VueClipboard)
Vue.use(SlideVerify)
Vue.use(ElementUI)

Vue.prototype.$md5 = md5
Vue.config.productionTip = false
Vue.prototype.$util = util
Vue.prototype.$user_info = util.getLocalStorage('user_info')
Vue.prototype.getUserInfo = getUserInfo
Vue.prototype.curlGet = curlGet
Vue.prototype.curlPost = curlPost
Vue.prototype.curlGetCode = curlGetCode
Vue.prototype.$goLink = goLink
Vue.prototype.pageChangeOne = pageChangeOne
Vue.prototype.shopDate = shopDate
Vue.prototype.$downloadByBlob = downloadByBlob
Vue.prototype.jumpLink = jumpLink
Vue.component('Navigation', navigation)
Vue.component('downloadExcel', JSONExcel)

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //这里判断用户是否登录，验证本地存储是否有token
        if (!util.getLocalStorage('user_info')) { // 判断当前的token是否存在
            console.log('请登录')
            next({
                path: '/',
            });
        } else {
            next()
        }
    } else {
        next() // 确保一定要调用 next()
    }
})

var vue = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default vue
