<template>
    <div v-if="visible" class="popup" @click.self="closePopup">
        <!-- 弹窗的内容 -->
        <div class="popup-content">
            <p class="popup_p">
                费用不足请及时充值。
            </p>
            <div class="flex" style="justify-content: flex-end;">
                <button class="up_box" @click.stop="closePopup">取消</button>
                <button class="close_box" @click.stop="confirm">去充值</button>
            </div>
        </div>
        <!-- 关闭按钮 -->
    </div>
</template>
   
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['fillActive']),
    },
    props: {
        visible: {
            type: [Boolean, String],
            default: false
        },


    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        confirm() {
            this.$router.push('/user/refill')
        }
    }
}
</script>
   
<style scoped lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: #fff;
    width: 40rem;
    /* height: 6rem; */
    padding: 2rem 1rem 1rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    text-align: left;

    .popup_p {
        color: #333;
        font-size: 1.2rem;

        span {
            font-size: 1.2rem;
            border-right: 0.1rem solid #333;
            padding: 0 0.5rem;
            box-sizing: border-box;

            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }
    }

    .close_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #2A82E4;
        color: #fff;
        border-radius: 0.2rem;
        border: 0;
        cursor: pointer;
    }

    .up_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #fff;
        color: #2A82E4;
        border: 0.1rem solid #aaa;
        border-radius: 0.2rem;
        margin-right: 1rem;
        cursor: pointer;

    }
}

.close-button {
    margin-top: 10px;
}
</style>