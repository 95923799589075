const state = {
    category_id: 1,
    order_status: ''
}
const mutations = {
    SETCATEGORYID(state, category_id) {
        state.category_id = category_id
    },
    MEDIAORDER_TAB(state, order_status) {
        state.order_status = order_status
    }
}
export default {
    state,
    mutations
}