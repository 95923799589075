export const media_list =[
    {
        id: 0,
        title: '首页',
        imgUrl: require('@/assets/user/home.png'),
        path: '/user/index'
    },
    {
        id: 1,
        title: '产品中心',
        imgUrl: require('@/assets/user/media.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '媒介套餐',
                path: '/user/SetMenu/SetMenuList'
            },
            {
                id: 2,
                title: '媒介资源',
                path: '/user/user_media/News',
            },
            {
                id: 3,
                title: '一言一文',
                path: '/wordNav',
            },
            {
                id: 4,
                title: '享视界',
                path: '/aiNav'
            },
            {
                id: 5,
                type_id:'user_purse',
                title: '用户权益包',
                path: '/user/userBenefitPackage',
                
            },
        ],
    },
    {
        id: 2,
        title: '稿件发布',
        imgUrl: require('@/assets/user/manuscript.png'),
        selectStatus: false,

        children: [
            {
                id: 1,
                title: '资讯门户发布',
                path: '/user/SetMenu/fill?id=1'
            },
            {
                id: 2,
                title: '自媒体发布',
                path: '/user/SetMenu/fill?id=2'
            },
            {
                id: 3,
                title: '纸媒介发布',
                path: '/user/SetMenu/fill?id=3'
            },
            {
                id: 4,
                title: '公众号发布',
                path: '/user/SetMenu/fill?id=4'
            },

            {
                id: 5,
                title: '微博发布',
                path: '/user/SetMenu/fill?id=5'
            },
            {
                id: 6,
                title: '小红书发布',
                path: '/user/SetMenu/fill?id=6'
            },
            {
                id: 7,
                title: '短视频发布',
                path: '/user/SetMenu/fill?id=7'
            },
            {
                id: 8,
                title: '套餐发布',
                path: '/user/SetMenu/fill?id=8'
            },
            {
                id: 9,
                title: '草稿箱',
                path: '/user/Draft'
            },
        ],
    },
    {
        id: 3,
        title: '订单管理',
        imgUrl: require('@/assets/user/order.png'),
        selectStatus: false,

        children: [
            {
                id: 1,
                order_type: 'order',
                order_status_total_num: 0,
                title: '媒介订单',
                path: '/user/MediaOrder'
            },
            {
                id: 2,
                order_type: 'package',
                package_status_total_num: 0,
                title: '套餐订单',
                path: '/user/PackageOrder'
            },
            {
                id: 3,
                title: 'AI记录',
                path: '/user/Myword'
            },
            {
                id: 4,
                title: '我的工单',
                path: '/user/MyOrder'
            },
        ],
    },
    {
        id: 4,
        title: '我的媒介',
        imgUrl: require('@/assets/user/MyMedia.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '我的资源',
                path: '/user/pub_media/pubNews'
            },
            {
                id: 2,
                order_type: 'draft',
                draft_status_total_num: 0,
                title: '我的收稿',
                path: '/user/Manuscript'
            },
            {
                id: 3,
                title: '售后记录',
                path: '/user/after_sale'
            },
        ],
    },
    {
        id: 5,
        title: '财务管理',
        imgUrl: require('@/assets/user/money.png'),
        selectStatus: false,

        children: [
            {
                id: 1,
                title: '在线充值',
                path: '/user/refill'
            },
            {
                id: 2,
                title: '提现管理',
                path: '/user/Withdraw'
            },
            {
                id: 3,
                title: '账单细明',
                path: '/user/AccountDetails'
            },
            {
                id: 4,
                title: '发票列表',
                path: '/user/Invoice'
            },
            {
                id: 5,
                title: '申请发票',
                path: '/user/apply_for_invoice/apply_for_invoice_data'
            },
            {
                id: 6,
                title: '钱包转换',
                path: '/user/wallet_conversion'
            },
        ],
    },
    {
        id: 6,
        title: '账户管理',
        imgUrl: require('@/assets/user/user.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '消息中心',
                path: '/user/MessageCenter'
            },
            {
                id: 2,
                title: '资料密码',
                path: '/user/Resource'
            },
            {
                id: 3,
                title: '企业认证',
                path: '/user/Enterprise'
            },
            {
                id: 4,
                title: '平台帮助',
                path: '/user/Help'
            },
        ],
    },
]
export const placard_list = [
    {
        id: 0,
        title: '首页',
        imgUrl: require('@/assets/user/home.png'),
        path: '/placard_user/placard_index'
    },
    {
        id: 1,
        title: '产品中心',
        imgUrl: require('@/assets/user/media.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '广告资源',
                path: '/placard_user/user_outdoors_placard_media',
            },
        ],
    },
    {
        id: 2,
        title: '订单管理',
        imgUrl: require('@/assets/user/order.png'),
        selectStatus: false,

        children: [
            {
                id: 1,
                order_status_total_num: 0,
                title: '投放订单',
                path: '/placard_user/placard_order',
                order_type: 'order'
            },
            {
                id: 2,
                title: '我的工单',
                path: '/placard_user/my_place_order'
            },
        ],
    },
    {
        id: 4,
        title: '我的广告',
        imgUrl: require('@/assets/user/MyMedia.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '发布广告',
                path: '/placard_user/pub_placard/pub_outdoors'
            },
            {
                id: 2,
                draft_status_total_num: 0,
                title: '投放管理',
                path: '/placard_user/placard_manuscript',
                order_type: 'draft'
            },
            {
                id: 3,
                title: '售后记录',
                path: '/placard_user/placard_after_sal'
            },
        ],
    },
    {
        id: 5,
        title: '财务管理',
        imgUrl: require('@/assets/user/money.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '在线充值',
                path: '/placard_user/placard_refill'
            },
            {
                id: 2,
                title: '提现管理',
                path: '/placard_user/placard_withdraw'
            },
            {
                id: 3,
                title: '账单细明',
                path: '/placard_user/placard_account_details'
            },
            {
                id: 4,
                title: '发票列表',
                path: '/placard_user/placard_invoice'
            },
            {
                id: 5,
                title: '申请发票',
                path: '/placard_user/placard_apply_for_invoice/placard_apply_for_invoice_data'
            },
            {
                id: 6,
                title: '钱包转换',
                path: '/placard_user/placard_wallet_conversion'
            },
        ],
    },
    {
        id: 6,
        title: '账户管理',
        imgUrl: require('@/assets/user/user.png'),
        selectStatus: false,
        children: [
            {
                id: 1,
                title: '消息中心',
                path: '/placard_user/MessageCenter'
            },
            {
                id: 2,
                title: '资料密码',
                path: '/placard_user/Resource'
            },
            {
                id: 3,
                title: '企业认证',
                path: '/placard_user/Enterprise'
            },
            {
                id: 4,
                title: '平台帮助',
                path: '/placard_user/Help'
            },
        ],
    },
]