<template>
  <!--  style="justify-content: space-between; align-items: center; " -->
  <div class="flex"
    style="width: 100%; position: absolute;z-index: 1; bottom: 20px;left: 0; padding: 0 20px; box-sizing: border-box;justify-content: space-between;">
    <div class="flex">
      <p class="count_num" style="margin-right: 10px;">共计：{{ count }}条记录，每页显示{{ length_num }}条记录</p>
      <p class="count_num" v-if="total_order_price">共计消费金额：￥{{ total_order_price }}</p>
    </div>
    <el-pagination :current-page="page" @current-change="currentTab" background layout="prev, pager, next, jumper"
      :total="total_page * 10">
      <!-- hide-on-single-page -->
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    total_page: Number,
    count: Number,
    length_num:Number,
    total_order_price: {
      type: [String, Number],
      default: ''
    },
    page: Number,
    length_num: {
      type: Number,
      default: 20
    }
  },
  methods: {
    // 切换页
    currentTab(val) {
      // console.log(val);
      // this.page = val
      // this.ListFn()
      // this.TabCheck()
      this.$emit('pageChange', val)
    },
    // 上一页
    prevClick() {

    },
    // 下一页
    nextClick() {

    },
  },
}
</script>

<style lang=scss scoped>
.count_num {
  font-size: 16px;
}
</style>