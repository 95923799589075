<template>
	<div>
		<div class="button_box">
			<div class="ct_box">
				<!-- 关于我们 帮助中心 留言建议 -->
				<div class="but_top_box">
					<ul class="flex">
						<li @click="goUrl(1)">
							<span></span>
							关于我们
						</li>
						<li @click="goUrl(2)">
							<span></span>
							帮助中心
						</li>
						<li @click="goUrl(3)">
							<span></span>
							留言建议
						</li>
					</ul>
				</div>
				<!-- 商务合作 -->
				<div class="but_box">
					<div class="lt_box">
						<img src="@/assets/logon.png" alt="" />
						<p class="text">商务合作：{{ webUrl.cooperate_tel }}</p>
						<p>地址：{{ webUrl.address }}</p>
						<!-- <img :src="require('../assets/home/shichang.png')" alt="" style="max-width: 60%" /> -->
					</div>
					<div class="rt_box">
						<div class="certificate_box flex">

							<img @click="business = true" class="certificate_img"
								:src="require('@/assets/home/gongan.png')" alt="" />
							<a class="certificate_img2" href="https://www.12377.cn/" target="_blank">
								<img :src="require('@/assets/home/bulang.png')" alt="" />
							</a>
							<div id="kxverify"></div>
						</div>
						<!-- <p class="text2">xxxx xxxx xxxx xxxx xxxx</p> -->
						<!-- <p>xxxx xxxx xxxx xxxx</p> -->
						<div class="leave_box flex">
							<p style="font-size: 1.2rem; width: 140px;">友情链接：</p>
							<div class="flex" style="flex-wrap: wrap;">
								<a style="color: #fff;font-size: 1.22rem;text-decoration: none;margin:0 1rem 1rem 0;"
									:href="item.url" target="_blank" v-for="(item, index) in links" :key="index">{{
							item.title }}</a>
							</div>
						</div>

					</div>
				</div>
				<p class="banquan flex">
					<img style="width: 1.5rem;margin-right: 0.5rem;" src="@/assets/put.png" alt="">
					<a href="https://beian.mps.gov.cn/#/query/webSearch?code=33010902003820" rel="noreferrer"
						target="_blank"
						style="margin-right: 0.8rem; text-decoration: none;font-size: 1.22rem;color: #fff;">浙公网安备33010902003820号</a>
					<a style="text-decoration: none;font-size: 1.22rem;color: #fff;"
						href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备15044227号-5</a>
				</p>
				<p class="banquan">版权所有:杭州宇耀网络技术有限公司 Copyright ◎2023-2025 All Right Reserved 技术支持:风正数智</p>

			</div>
		</div>
		<!-- 帮助中心 -->
		<el-dialog title="帮助中心" :append-to-body="true" :visible.sync="helpShow" :show-close="false">
			<div v-html="helpContent"></div>
		</el-dialog>
		<!-- 留言建议 -->
		<el-dialog title="留言建议" :append-to-body="true" :visible.sync="messageShow" :show-close="false">
			<div class="el_dialog_content">
				<div class="el_dialog_title flex">
					<div class="el_dialog_ct">
						<p>姓名</p>
						<input type="text" v-model="messageForm.name" />
					</div>
					<div class="el_dialog_ct">
						<p>电话</p>
						<input type="text" v-model="messageForm.mobile" />
					</div>
					<div class="el_dialog_ct">
						<p>邮箱</p>
						<input type="text" v-model="messageForm.email">
					</div>
				</div>
				<div class="el_dialog_textarea">
					<p>留言建议</p>
					<el-input type="textarea" :autosize="{ minRows: 8 }" v-model="messageForm.content">
					</el-input>
				</div>
				<button class="referFn" @click="referFn">提交并发送</button>
			</div>
		</el-dialog>
		<!-- 营业执照 -->
		<el-dialog title="营业执照" :append-to-body="true" :visible.sync="business" :show-close="false">
			<img :src="businessImg" alt="" style="width: 100%;" />
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: '',
	created() { },
	mounted() {
		this.curlGet('/api/article/list', {
			cate_id: 6,

		}).then(res => {
			if (res.data.code) {
				this.helpContent = res.data.data[0].content
			}
		})
		this.getAipList()
		this.getwebsite()
		// let script = document.createElement('script');
		// script.type = 'text/javascript';
		// script.src = 'https://kxlogo.knet.cn/seallogo.dll?sn=e24041533010086094sqnc8462&h=43';
		// let certificate_box  = document.querySelector('.certificate_box')
		// document.body.appendChild(script);
		// console.log(script);
		// let script = document.createElement('script');
		this.addScript('kXScript', '//kxlogo.knet.cn/seallogo.dll?sn=e24041533010086094sqnc8462&h=47', 'kxverify')
	},
	data() {
		return {
			show: false,
			helpShow: false,
			messageShow: false,
			helpContent: '',
			messageForm: {
				name: '',
				mobile: '',
				email: '',
				content: ''
			},
			webUrl: {
				address: '',
				after_tel: '',
				aftersale_qq: '',
				before_tel: '',
				complaint_tel: '',
				cooperate_tel: '',
				fax: '',
				presale_qq: ''
			},
			business: false,
			businessImg: require('@/assets/home/businessImg.png'),
			links: [],
		}
	},
	methods: {
		getwebsite() {
			this.curlGet('/api/index/website').then(res => {
				if (res.data.code) {
					this.webUrl = res.data.data
				}
			})
		},
		getAipList() {
			this.curlGet('/api/links').then(res => {
				if (res.data.code) {
					this.links = res.data.data
				}
			})
		},
		goUrl(index) {
			if (index == 1) {
				this.$router.push('/about')
			} else if (index == 2) {
				this.helpShow = true

			} else {
				this.messageShow = true
			}
		},
		referFn() {
			if (this.messageForm.name == '') {
				return this.$message({
					message: '请输入姓名',
					type: 'error',
					offset: 100,
				});

			}
			if (this.messageForm.mobile == '') {
				return this.$message({
					message: '请输入手机号',
					type: 'error',
					offset: 100,
				});
			}
			if (this.messageForm.email == '') {
				return this.$message({
					message: '请输入邮箱',
					type: 'error',
					offset: 100,
				});
			}
			if (this.messageForm.content == '') {
				return this.$message({
					message: '请输入内容',
					type: 'error',
					offset: 100,
				});
			}
			this.curlPost('/api/leave_msg/save', this.messageForm).then(res => {
				console.log(res);
				if (res.data.code) {
					this.messageShow = false
					return this.$message({
						message: '提交成功,等待客服回馈',
						type: 'success'
					});
				} else {
					return this.$message({
						message: res.data.msg,
						type: 'error',
						offset: 100,
					});
				}
			})
		},
		addScript(id, src, parentId) {
			let script = document.createElement('script')
			script.id = id
			script.type = 'text/javascript'
			script.src = src
			document.getElementById(parentId).appendChild(script)
		}

	},
}
</script>
<style lang=scss scoped>
.button_box {
	width: 100%;
	min-width: 1200px;
	// height: 80vh;
	background: #026bbc;

	.ct_box {
		max-width: 70%;
		min-width: 1200px;
		// height: 90vh;
		margin: 0 auto;

		.but_top_box {
			border-bottom: 0.2rem solid #2878b5;
			padding: 2rem 0;

			ul {
				margin: 0 auto;
				max-width: 75%;
				justify-content: space-around;
				max-height: 10rem;

				li {
					cursor: pointer;
					color: #fff;
					font-size: 1.5rem;
					display: flex;
					align-items: center;

					&:nth-child(1) {
						span {
							background: url('~@/assets/home/bottom_07.png') no-repeat;
							background-size: 100%;
						}
					}

					&:nth-child(2) {
						span {
							background: url('~@/assets/home/bottom_09.png') no-repeat;
							background-size: 100%;
						}
					}

					&:nth-child(3) {
						span {
							background: url('~@/assets/home/bottom_05.png') no-repeat;
							background-size: 90%;
						}
					}

					span {
						display: inline-block;
						width: 3.5rem;
						height: 3.5rem;
						margin-right: 2.5rem;
					}
				}
			}
		}

		// 底部
		.but_box {
			width: 100%;
			display: flex;
			// flex-wrap: wrap;
			padding-bottom: 4rem;
			margin-top: 3rem;

			.lt_box {
				min-width: 45%;
				text-align: center;
				border-right: 0.2rem solid #2878b5;

				.text {
					margin: 1rem 0;
				}

				p {
					color: #fff;
					font-size: 1.3rem;
				}
			}

			.rt_box {
				// min-width: 670px;
				padding-left: 3rem;
				text-align: center;

				.certificate_box {
					justify-content: center;

					a {
						max-width: 17%;

						img {
							width: 100%;
						}
					}

					.certificate_img {
						cursor: pointer;
						max-width: 17%;
						object-fit: cover;

					}

					.certificate_img2 {
						cursor: pointer;
						max-width: 22%;
						object-fit: cover;

					}

				}

				p {
					color: #fff;
					font-size: 1.3rem;
				}

				.text {
					margin: 2rem 0;
				}

				.text2 {
					margin-bottom: 2rem;
				}
			}
		}
	}
}

.banquan {
	font-size: 1.22rem;
	color: #fff;
	text-align: center;
	padding-bottom: 1rem;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
}

::v-deep .el-dialog {
	border-radius: 0.83rem;
	z-index: 1001;
	width: 50%;
	position: relative;

	.el-dialog__header {
		background: #3EA0FE;
		padding: 0.5rem 1rem;
		box-sizing: border-box;
		border-radius: 0.83rem 0.83rem 0rem 0rem;
		position: relative;

		// &::after {
		// 	content: "";
		// 	width: 1.4rem;
		// 	height: 1.4rem;
		// 	background: url('../assets/home/close.png')no-repeat;
		// 	background-size: 100%;
		// 	position: absolute;
		// 	right: 2rem;
		// 	top: 0.8rem;
		// }

		.el-dialog__title {
			color: #fff;
			font-size: 1.33rem;

		}
	}

	.el_dialog_content {
		.el_dialog_title {
			justify-content: space-between;
			margin-bottom: 0.5rem;

			.el_dialog_ct {
				p {
					color: #333;
					font-size: 1.33rem;
					margin-bottom: 0.5rem;
				}

				input {
					outline: none;
					width: 20rem;
					height: 2.33rem;
					border-radius: 0.83rem;
					background: #FFFFFF;
					border: 0.08rem solid #AAAAAA;
					font-size: 1.2rem;
					text-indent: 1rem;
				}
			}
		}

		.el_dialog_textarea {
			p {
				color: #333;
				font-size: 1.33rem;
				margin-bottom: 0.5rem;
			}
		}
	}

	.referFn {
		width: 7.08rem;
		height: 3.33rem;
		background: #3EA0FE;
		color: #fff;
		border: 0;
		border-radius: 0.83rem;
		margin-top: 1rem;
		cursor: pointer;
	}

}

::v-deep .el-textarea__inner {
	border: 0.08rem solid #AAAAAA !important;

}

.leave_box {
	margin-top: 1rem;
	width: 100%;
	text-align: left;

}
</style>